
import { useEvent } from '@/composable/useEvent'
import format from 'date-fns/format'
import {
  computed,
  defineComponent,
  onBeforeMount,
  watch,
  provide,
  ref,
  onUnmounted,
} from 'vue'
import { useRoute } from 'vue-router'
import { useModal } from '@/composable/useModal'
import DeviationVehicleTable from '@/components/deviation/deviation-vehicle/DeviationVehicleTable.vue'
import DeviationDelayTable from '@/components/deviation/deviation-delay/DeviationDelayTable.vue'
import DeviationCancelTable from '@/components/deviation/deviation-cancel/DeviationCancelTable.vue'
import DeviationCrewTable from '@/components/deviation/deviation-crew/DeviationCrewTable.vue'
import DeviationDisruptionTable from '@/components/deviation/deviation-disruption/DeviationDisruptionTable.vue'
import DeviationVehicleFormModal from '@/components/deviation/deviation-vehicle/DeviationVehicleFormModal.vue'
import DeviationDelayFormModal from '@/components/deviation/deviation-delay/DeviationDelayFormModal.vue'
import DeviationCancelFormModal from '@/components/deviation/deviation-cancel/DeviationCancelFormModal.vue'
import DeviationCrewFormModal from '@/components/deviation/deviation-crew/DeviationCrewFormModal.vue'
import DeviationDisruptionFormModal from '@/components/deviation/deviation-disruption/DeviationDisruptionFormModal.vue'
import DeviationDeleteModal from '@/components/deviation/DeviationDeleteModal.vue'
import { useProfile } from '@/composable/useProfile'
import EventRowActionsDropdown from '@/components/event/EventRowActionsDropdown.vue'
import EventFormModal from '@/components/event/EventFormModal.vue'
import EventDeleteModal from '@/components/event/EventDeleteModal.vue'
import EventCloseModal from '@/components/event/EventCloseModal.vue'
import EventOpenModal from '@/components/event/EventOpenModal.vue'
import EventFordModal from '@/components/event/EventFordModal.vue'
import { routerPush } from '@/router'
import { CausingVehicle } from '@/types/causing-vehicle'
import { getVehicleDefect } from '@/api/vehicle-defect/getVehicleDefect'
import EventPenaltyAssessmentModal from '@/components/event/EventPenaltyAssessmentModal.vue'
import { useXod } from '@/composable/useXod'
import { useDeviationVehicleProposal } from '@/composable/useDeviationVehicleProposal'
import { useDeviationCancelProposal } from '@/composable/useDeviationCancelProposal'
import { useDeviationDelayProposal } from '@/composable/useDeviationDelayProposal'
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import { addDays, areIntervalsOverlapping } from 'date-fns'
import { getMpkEvent } from '@/api/mpk/getMpkEvent'
import DeviationDelayMPKModal from '@/components/deviation/deviation-delay/DeviationDelayMPKModal.vue'
import orderBy from 'lodash.orderby'
import { getTracks } from '@/api/lupp/getTracks'
import { LuppTrack } from '@/types/lupp-track'
import { useUserSettings } from '@/composable/useUserSettings'
import { socket } from '@/services/socket'

export default defineComponent({
  name: 'Event',

  components: {
    DeviationVehicleTable,
    DeviationDelayTable,
    DeviationCancelTable,
    DeviationCrewTable,
    DeviationDisruptionTable,
    DeviationVehicleFormModal,
    DeviationDelayFormModal,
    DeviationCancelFormModal,
    DeviationCrewFormModal,
    DeviationDisruptionFormModal,
    DeviationDeleteModal,
    EventRowActionsDropdown,
    EventFormModal,
    EventCloseModal,
    EventDeleteModal,
    EventOpenModal,
    EventFordModal,
    EventPenaltyAssessmentModal,
    DeviationDelayMPKModal,
  },

  setup() {
    const deviationVehicleFormModal = useModal()
    const deviationDelayFormModal = useModal()
    const deviationCancelFormModal = useModal()
    const deviationCrewFormModal = useModal()
    const deviationVehicleDeleteModal = useModal()
    const deviationDisruptionFormModal = useModal()
    const route = useRoute()
    const { can, projectDependentInputFields, currentProject } = useProfile()
    const { data: xodData, loading: loadingXod, search: searchXod } = useXod()
    const formModal = useModal()
    const deleteModal = useModal()
    const closeModal = useModal()
    const openModal = useModal()
    const fordModal = useModal()
    const penaltyAssessmentModal = useModal()
    const showProposals = ref(true)
    const tabpanels = ref<InstanceType<typeof QTabPanel> | null>(null)
    const tableHeight = ref('100%')
    const observeExpansion = ref<HTMLDivElement | null>(null)
    const project = currentProject.value?.name as string
    const {
      fetchAll: fetchAllDeviationVehicleProposals,
      data: deviationVehicleProposals,
      loading: loadingDeviationVehicleProposals,
    } = useDeviationVehicleProposal()

    const {
      fetchAll: fetchAllDeviationCancelProposals,
      data: deviationCancelProposals,
      loading: loadingDeviationCancelProposals,
    } = useDeviationCancelProposal()

    const {
      fetchAll: fetchAllDeviationDelayProposals,
      data: deviationDelayProposals,
      loading: loadingDeviationDelayProposals,
    } = useDeviationDelayProposal()

    onBeforeMount(() => {
      deviationVehicleProposals.value = []
      deviationCancelProposals.value = []
      deviationDelayProposals.value = []
    })

    socket.instance?.on(
      'delay-import-notification',
      (data: { startDate: string; endDate: string; project: string }) => {
        if (data.project !== currentProject.value?.name) return
        const period = proposalPeriod(eventPeriod.value)
        const overlapping = areIntervalsOverlapping(
          {
            start: new Date(period.from),
            end: new Date(period.to),
          },
          { start: new Date(data.startDate), end: new Date(data.endDate) }
        )

        if (!overlapping) return

        fetchAllDeviationDelayProposals(period)
      }
    )

    provide('ford-modal', fordModal)
    provide('form-modal', formModal)
    provide('delete-modal', deleteModal)
    provide('close-modal', closeModal)
    provide('open-modal', openModal)
    provide('deviation-vehicle-form-modal', deviationVehicleFormModal)
    provide('deviation-delay-form-modal', deviationDelayFormModal)
    provide('deviation-cancel-form-modal', deviationCancelFormModal)
    provide('deviation-crew-form-modal', deviationCrewFormModal)
    provide('deviation-disruption-form-modal', deviationDisruptionFormModal)

    provide('deviation-delete-modal', deviationVehicleDeleteModal)

    provide('penalty-assessment-modal', penaltyAssessmentModal)

    const tabs = computed(() => {
      return [
        {
          label: 'Inställt tåg',
          name: 'deviationCancel',
          prop: 'deviationCancels',
          permission: 'deviationManagement.deviationCancel.list',
        },
        {
          label: 'Fordonsavvikelse',
          name: 'deviationVehicle',
          prop: 'deviationVehicles',
          permission: 'deviationManagement.deviationVehicle.list',
        },
        {
          label: 'Förseningar',
          name: 'deviationDelay',
          prop: 'deviationDelays',
          permission: 'deviationManagement.deviationDelay.list',
        },
        {
          label: 'Bemanning',
          name: 'deviationCrew',
          prop: 'deviationCrews',
          permission: 'deviationManagement.deviationCrew.list',
        },
        {
          label: 'Störningshantering',
          name: 'deviationDisruption',
          prop: 'deviationDisruptions',
          permission: 'deviationManagement.deviationDisruption.list',
        },
      ]
        .filter((tabItem) => can(tabItem.permission))
        .filter((x) => {
          if (x.name === 'deviationDisruption') {
            return project !== 'gotalandstag'
          }
          return true
        })
    })
    const {
      data: event,
      fetch: fetchEvent,
      loading: loadingStateEvents,
    } = useEvent()
    const { settings } = useUserSettings()
    const eventUuid = route.params.eventUuid as string
    const activeTab = ref(
      settings.value[project].event.activeEventTab ||
        [
          'deviationCancel',
          'deviationVehicle',
          'deviationDelay',
          'deviationCrew',
          'deviationDisruption',
        ].find((deviationType) =>
          can(`deviationManagement.${deviationType}.list`)
        )
    )

    watch(
      () => activeTab.value,
      (value) => {
        if (value) {
          settings.value[project].event.activeEventTab = value
        }
      }
    )

    const loadingTrvIds = ref(true)
    const trvValidIds = ref<{ [key: string]: boolean }>({})

    watch(
      () => event.value,
      async () => {
        loadingTrvIds.value = true
        const trvIds = event.value?.eventTrvs || []
        for (const eventId of trvIds) {
          const res = await getMpkEvent(eventId.trvId)
          if (res.data) {
            trvValidIds.value[eventId.trvId] = true
          }
        }
        loadingTrvIds.value = false
      },
      {
        immediate: true,
      }
    )

    const badgeColor = computed(() => (event.value?.end ? 'grey-5' : 'primary'))

    function formatDate(date: string) {
      return format(new Date(date), 'yyyy-MM-dd')
    }

    function onDeleteEvent() {
      routerPush(`events`)
    }

    function onClickFordChip(causingVehicle: CausingVehicle) {
      fordModal?.openModal({
        cb: async (setData) => {
          const defectNumber = causingVehicle.ford as number
          await getVehicleDefect(defectNumber)
            .then(({ data }) => {
              setData(data)
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    function onClickLinkedEvent() {
      if (!event.value || !event.value.linkedEventUuid) return
      routerPush('event', {
        eventUuid: event.value.linkedEventUuid,
      })
    }

    const xodColumnValue = (xodId: number) => {
      const xod = xodData.value.find((x) => xodId === x.trafficDisturbanceId)
      if (xod) {
        return xod.title
      }

      return 'Xod titel hittades inte'
    }

    const deviationVehicles = computed(() => {
      const data = event.value?.deviationVehicles || []
      if (!showProposals.value) return data
      return [...deviationVehicleProposals.value, ...data]
    })

    const deviationCancels = computed(() => {
      const data = event.value?.deviationCancels || []
      if (!showProposals.value) return data
      return [...deviationCancelProposals.value, ...data]
    })

    const deviationDelays = computed(() => {
      const data = event.value?.deviationDelays || []
      if (!showProposals.value) return data
      return [...deviationDelayProposals.value, ...data].filter((x) => {
        return tracks.value.some((t) => t.label === x.bana && t.visible)
      })
    })

    const eventPeriod = ref({
      from: '',
      to: '',
    })

    const resizeTable = () => {
      if (tabpanels.value) {
        const rect = tabpanels.value.$el.getBoundingClientRect()
        tableHeight.value = `${window.innerHeight - rect.top}px`
      }
    }

    const proposalPeriod = (period: { from: string; to: string }) => {
      return {
        from: period.from,
        to: format(addDays(new Date(period.from), 2), 'yyyy-MM-dd'),
      }
    }

    const init = async () => {
      const data = await fetchEvent(eventUuid)
      eventPeriod.value = {
        from: format(new Date(data.start), 'yyyy-MM-dd'),
        to: format(new Date(data.start), 'yyyy-MM-dd'),
      }
      if (activeTab.value) {
        await onClickTab(activeTab.value)
      }

      resizeTable()
    }

    const onClickTab = async (tabName: string) => {
      if (tabName === 'deviationCancel') {
        fetchAllDeviationCancelProposals(proposalPeriod(eventPeriod.value))
      }

      if (tabName === 'deviationVehicle') {
        fetchAllDeviationVehicleProposals(proposalPeriod(eventPeriod.value))
      }

      if (tabName === 'deviationDelay') {
        await fetchAllDeviationDelayProposals(proposalPeriod(eventPeriod.value))
      }
    }

    window.addEventListener('resize', resizeTable)

    const resizeObserver = new ResizeObserver(() => {
      resizeTable()
    })

    watch(
      () => observeExpansion.value,
      () => {
        if (observeExpansion.value) {
          resizeObserver.observe(observeExpansion.value)
        }
      }
    )

    onUnmounted(() => {
      window.removeEventListener('resize', resizeTable)
      resizeObserver.disconnect()
    })

    const mpkModal = useModal()
    provide('delay-mpk-modal', mpkModal)

    function onClickMpkChip(mpk_event_id: string) {
      const eventId = mpk_event_id.split(' ')[0].replace('H', '')
      mpkModal?.openModal({
        cb: async (setData) => {
          await getMpkEvent(eventId)
            .then(({ data }) => {
              setData({
                eventId,
                mpkData: data,
              })
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    interface TrackItem {
      label: string
      visible: boolean
    }
    const tracks = ref<TrackItem[]>([])
    const tracksSelected = ref<boolean | null>(false)

    const localstorageBanor = settings.value[project].delay.tracks

    const setBanor = (luppTracks: LuppTrack[]) => {
      const banor = orderBy(
        [...new Set(luppTracks.map((x) => x.description))],
        (x) => x,
        ['asc']
      )
      tracks.value = banor
        .map((label) => {
          const bana = localstorageBanor.find((x) => x.label === label)
          return {
            label,
            visible: bana ? bana.visible : true,
          }
        })
        .filter((x) => x.label !== 'Göteborg – Halmstad')
    }

    const fetchTracks = async () => {
      const { data } = await getTracks()
      setBanor(data)
    }

    fetchTracks()

    watch(
      () => tracks.value,
      (value) => {
        if (value.every((x) => x.visible)) {
          tracksSelected.value = true
        } else if (value.every((x) => !x.visible)) {
          tracksSelected.value = false
        } else {
          tracksSelected.value = null
        }
      },
      {
        deep: true,
        immediate: true,
      }
    )

    const onTrackGroupUpdate = (value: boolean) => {
      tracks.value.forEach((x) => {
        x.visible = value
      })
    }

    const filterTrackCount = computed(() => {
      return tracks.value.filter((x) => x.visible).length
    })

    watch(
      () => tracks.value,
      (value) => {
        settings.value[project].delay.tracks = value
      }
    )

    init()

    return {
      onClickTab,
      tabs,
      eventUuid,
      formatDate,
      tableHeight,
      activeTab,
      event,
      badgeColor,
      loadingStateEvents,
      deviationVehicleFormModal,
      deviationDelayFormModal,
      deviationCancelFormModal,
      deviationCrewFormModal,
      deviationVehicleDeleteModal,
      deviationDisruptionFormModal,
      can,
      formModal,
      deleteModal,
      closeModal,
      openModal,
      onDeleteEvent,
      fordModal,
      onClickFordChip,
      projectDependentInputFields,
      onClickLinkedEvent,
      penaltyAssessmentModal,
      searchXod,
      loadingXod,
      xodColumnValue,
      deviationVehicles,
      deviationCancels,
      deviationDelays,
      loadingDeviationVehicleProposals,
      loadingDeviationCancelProposals,
      loadingDeviationDelayProposals,
      showProposals,
      tabpanels,
      observeExpansion,
      onClickMpkChip,
      mpkModal,
      trvValidIds,
      loadingTrvIds,
      tracksSelected,
      filterTrackCount,
      tracks,
      onTrackGroupUpdate,
      fetchEvent,
    }
  },
})
