import { render } from "./Event.vue?vue&type=template&id=b9357c9a"
import script from "./Event.vue?vue&type=script&lang=ts"
export * from "./Event.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QDialog,QBadge,QBtn,QIcon,QSeparator,QSpinner,QList,QExpansionItem,QCard,QCardSection,QTabs,QTab,QTabPanels,QTabPanel,QToggle,QBtnDropdown,QItemLabel,QItem,QItemSection,QCheckbox});
